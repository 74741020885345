const COMPENSATE_SCREEN_ = {
  START_DESTINATION: 0, 
  CONNECTING_FLIGHTS: 0,
  DIRECT_FLIGHT_DATE: 0,
  AFFECTED_FLIGHT_DATE: 0,
  MOBILE_VALIDATION: 0,
  DIRECT_FLIGHT_SEARCH: 0,
  AFFECTED_FLIGHT_SEARCH: 0,
  LAST_LEG_FLIGHT_DATE: 0,
  LAST_LEG_FLIGHT_SEARCH: 0,
  REPLACEMENT_FLIGHT_DATE: 0,
  REPLACEMENT_FLIGHT_SEARCH: 0,
  DELAY_REASON: 0,
  PASSENGERS: 0,
  ADDRESS: 0,
  OTHER_PASSENGERS: 0,
  SIGNATURE: 0,
  EVIDENCE: 0,
  SUMMARY: 0,
  OTHER_COMMENTS: 0,
  END: 0,
  No_CLAIM: 0,
  ERROR: 0
};

export const COMPENSATE_OBJECT = {
  START_DESTINATION: {
    pageNumber : '01a',
    pageProgress: '12.5%'
  },
  CONNECTING_FLIGHTS: {
    pageNumber : '01b',
    pageProgress: '18.75%'
  },
  DIRECT_FLIGHT_DATE: {
    pageNumber : '02a',
    pageProgress: '25%'
  },
  AFFECTED_FLIGHT_DATE: {
    pageNumber : '02b',
    pageProgress: '25%'
  },
  MOBILE_VALIDATION: {
    pageNumber : '03z',
    pageProgress: '31.25%'
  },
  DIRECT_FLIGHT_SEARCH: {
    pageNumber : '03a',
    pageProgress: '37.5%'
  },
  AFFECTED_FLIGHT_SEARCH: {
    pageNumber : '03b',
    pageProgress: '37.5%'
  },
  LAST_LEG_FLIGHT_DATE: {
    pageNumber : '02c',
    pageProgress: '41.66%'
  },
  LAST_LEG_FLIGHT_SEARCH: {
    pageNumber : '03c',
    pageProgress: '45.82%'
  },
  REPLACEMENT_FLIGHT_DATE: {
    pageNumber : '02d',
    pageProgress: '45.82%'
  },
  REPLACEMENT_FLIGHT_SEARCH: {
    pageNumber : '03d',
    pageProgress: '45.82%'
  },
  DELAY_REASON: {
    pageNumber : '04a',
    pageProgress: '50%'
  },
  PASSENGERS: {
    pageNumber : '05',
    pageProgress: '62.5%'
  },
  ADDRESS: {
    pageNumber : '06',
    pageProgress: '75%'
  },
  OTHER_PASSENGERS: {
    pageNumber : '07',
    pageProgress: '87.5%'
  },
  SIGNATURE: {
    pageNumber : '08',
    pageProgress: '93.75%'
  },
  EVIDENCE: {
    pageNumber : '09',
    pageProgress: '100%'
  },
  SUMMARY:{
    pageNumber: '10',
    pageProgress: '100%'
  },
  OTHER_COMMENTS: {
    pageNumber : '11',
    pageProgress: '100%'
  },
  END: {
    pageNumber : '12',
    pageProgress: '100%'
  },
  No_CLAIM: {
    pageNumber : '04b',
    pageProgress: '100%'
  },
  ERROR: {
    pageNumber : '99',
    pageProgress: '100%'
  },
}
export const COMPENSATE_NOCLAIM_REASON = {
  'Airline Unknown': 'Airline Unknown',
  'Airline on exclusion list': 'Airline on exclusion list',
  'Flight was cancelled': 'Flight was cancelled',
  'Flight was reinstated': 'Flight was reinstated',
  'Delayed less than 3 hours': 'Delayed less than 3 hours',
  'Airport and Airline out of Jurisdiction':  'Airport and Airline out of Jurisdiction',
  'Valid Jurisdiction': 'Valid Jurisdiction',
};

export const COMPENSATE_STEPPER_ = {
  NUMBER_OF_PASSENGERS: {
    label: 'Enter details',
    image: 'img/f-stepper-1.png'
  },
  START_DESTINATION: {
    label: 'Enter details',
    image: 'img/f-stepper-1.png'
  },
  DIRECT_FLIGHT_DATE: {
    label: 'Enter details',
    image: 'img/f-stepper-1.png'
  },
  DIRECT_FLIGHT_SEARCH: {
    label: 'Enter details',
    image: 'img/f-stepper-1.png'
  },
  MANUAL_FLIGHT_DETAILS: {
    label: 'Enter details',
    image: 'img/f-stepper-1.png'
  },
  DELAY_REASON_1: {
    label: 'Delay Reason',
    image: 'img/f-stepper-2.png'
  },
  DELAY_REASON_3: {
    label: 'Delay Reason',
    image: 'img/f-stepper-2.png'
  },
  PASSENGERS: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  ADDRESS: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  CONTACT_DETAILS: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  OTHER_PASSENGERS: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  SIGNATURE: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  EVIDENCE: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  OTHER_COMMENTS: {
    label: 'Complete detail',
    image: 'img/f-stepper-2.png'
  },
  END: {
    label: 'Confirmation',
    image: 'img/f-stepper-1.png'
  },
};
Object.keys(COMPENSATE_SCREEN_).forEach(
  (key, index) => (COMPENSATE_SCREEN_[key] = index)
);
export const COMPENSATE_SCREEN = COMPENSATE_SCREEN_;

export const EACH_PASSENGER_CLAIM = (compensate) =>{
 const claimValue = flightbotObject(compensate).claimValue;
   return claimValue
  }

export const currencyType = (compensate) => flightbotObject(compensate).currencyType === 'GBP' ? '£' : '€'

export const flightbotObject = (compensate) =>{
   return compensate?.replacementFlight ||
  compensate?.lastLeg ||
  compensate?.flightData
}
export const AffectedFlightAirline = (compensate) => {
  return compensate?.flightData?.codeshare?.operatingFlight || compensate?.flightData?.operatingAirlineName
}

export const COMPENSATION_SUBMIT_ENDPOINT = 
  'https://datastore.tanantech.co.uk/1.0/data';

const baseUrl = new URL(window.location.href).host.includes('localhost')
  ? 'https://dev.app.airtravelclaim.com'
  : new URL(window.location.href).origin

export const GOOGLE_REVIEW_ENDPOINT = () => {
  return `${baseUrl}/2.0/api/google-reviews`
}

export const SMS_SERVICE_URL = () => {
  return `${baseUrl}/2.0/api/sms-service`;
} 

export const EMAIL_SERVICE_URL = () => {
  return `${baseUrl}/2.0/api/email-service`;
} 

export const SEARCH_AIRPORT_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/airport-search`;
}

export const GET_FLIGHT_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/flight-search`;
}

export const GET_VALID_CLAIM_DATA = () => {
  return `${baseUrl}/2.0/api/valid-claim`
}

export const SEND_OTP_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/authenticate`;
} 

export const GET_ALL_AIRLINES_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/all-airlines`;
} 

export const GET_CONNECTING_FLIGHT_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/calculation-validator`;
} 

export const GET_ADDRESS_LOOKUP_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/address-lookup`;
} 

export const GET_VALID_YEARS = () => {
  return `${baseUrl}/2.0/api/valid-claim-years`
}

export const CRM_SUBMIT_ENDPOINT = () =>{
  return `${baseUrl}/2.0/api/create-claim`;
} 

export const CRM_UPDATE_ENDPOINT = (caseId) => { 
    return `${baseUrl}/2.0/api/update-claim/${caseId}`;
  }

export const CRM_UPDATE_STATE = (caseId) => {
  return `${baseUrl}/2.0/api/update-state/${caseId}`;
}
