import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { COMPENSATE_SCREEN } from "../../constants";
import FormActionButton from "../shared/FormActionButton";
import Text from "../shared/Text";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import Label from "../shared/Label";
import { faUnderline } from "@fortawesome/free-solid-svg-icons";
import { GET_ADDRESS_LOOKUP_ENDPOINT } from "../../constants";
import { isValidMobile,isValidLandline, isValidPostCode } from "../../utils/utils";
import ListRadioOptions from "../shared/ListRadioOptions";
import Button_ from "../shared/Button";
import axios from "axios";
import { COMPENSATE_STEP } from "../../store/constants";

export default function Address({ compensate, onNext, onBack }) {
    console.log(compensate)
    const MAP_API_KEY = "AIzaSyB7IC7WCDbVkitaLIx878tKfvISZOCfRTA";
    const [address, setAddress] = useState(compensate.address)
    const [streetNumber, setstreetNumber] = useState(compensate.streetNumber)
    const [address1, setAddress1] = useState(compensate.address1)
    const [addressLine2, setAddressLine2] = useState(compensate.addressLine2)
    const [city, setCity] = useState(compensate.city)
    const [postcode, setPostcode] = useState(compensate.postcode)
    const [county, setCounty] = useState(compensate.county)
    const [country, setCountry] = useState(compensate.country)

    //const [emailAddress, setEmailAddress] = useState(compensate.emailAddress)
    const [mobileNumber, setMobileNumber] = useState(compensate.mobileNumber ?? '')
    const [whatsappOptIn, setWhatsappOptIn] = useState(compensate.whatsappOptIn)
    //const [landlineNumber, setlandlineNumber] = useState(compensate.landlineNumber ?? '')
    const [ukResident, setUkResident] = useState(compensate.ukResident ?? true)
    const [addressData, setAddressData] = useState(null)
    const [addressSelected, setAddressSelected] = useState(compensate.addressSelected ?? '')


    const [ukAddressError, setUkAddressError] = useState(false)
    const [address1Error, setaddress1Error] = useState(false);
    const [cityError, setcityError] = useState(false);
    const [countryError, setcountryError] = useState(false);
    const [postcodeError, setpostcodeError] = useState(false);

    const [addressError, setaddressError] = useState(false);

    const [validMobileError, setvalidMobileError] = useState(false);
    const [validLandlineNumberError, setvalidLandlineNumberError] = useState(false);
    
    

    const intl = useIntl();


    const addFunc = (event) => {
        setAddress(event)
        if(event == undefined || event == ''){
            setaddressError(true)
        } else {
            setaddressError(false) 
        }
    }

    const mapAddress = (place) => {
        let address1 = "";
        let address2 = "";
        console.log(place)
        setAddress1('');
        setAddressLine2('');
        setCity('');
        setPostcode('');
        setCountry('');

        const u = place?.address_components;
      if(u.length > 0){
        for (let i = 0; i< u.length-1;i++){
            console.log(u[i]?.types[0])
            if(u[i]?.types[0] == 'postal_town'){
                setCity(u[i]?.long_name);
                break;
            } else {
                if(u[i]?.types[0] == 'administrative_area_level_1'){
                    setCity(u[i]?.long_name);
                    break;
                 } else {
                    if (u[i]?.types[0] == 'locality'){
                    setCity(u[i]?.long_name);
                    break;
                    }
                 }
                  
            }
        }
    }

    
        for (const component of (place?.address_components ?? [])) {
            const componentType = component.types[0];



            switch (componentType) {
                case "street_number":
                    address1 += component.long_name + " ";
                    break;

                case "route":
                    address1 += component.long_name;
                    break;

                case "administrative_area_level_4":
                    address2 += component.long_name;
                    break;

                case "sublocality_level_1":
                    address2 += component.long_name;
                    break;

                case "locality":
                    address2 += component.long_name;
                    break;
                case "postal_code":
                        setPostcode(component.long_name);
                        break;
                case "postal_code_prefix":
                        setPostcode(component.long_name);
                        break;
    
        
                case "administrative_area_level_2":
                        setCounty(component.long_name);
                        break;
                case "country":
                        setCountry(component.long_name);
                        break;


                

            }

           
        }


      
  

        setAddress1(address1)
        setAddressLine2(address2)
        address1 == '' ? setaddress1Error(true) : setaddress1Error(false)  
        checkValidation()

    }


 

    // const setAddresss = (e) => {
    //     setAddress1(e.split(',')[0])
    //     // setAddressLine2(e.split(',')[1])
    // }

    const addressFun = (event) =>{
        console.log(event)
        setAddress1(event)
        event == ''? setaddress1Error(true) : setaddress1Error(false)      
    }


    const countryFun = (event) =>{
        setCountry(event)
        event == '' ? setcountryError(true) : setcountryError(false)      
    }

    const cityFun = (event) =>{
        setCity(event)
        event == '' ? setcityError(true) : setcityError(false)      
    }

    const postcodeFun = (event) =>{
        setPostcode(event)
        event == '' ? setpostcodeError(true) : setpostcodeError(false)      
    }


    const checkValidation = () =>{
        city == '' ? setcityError(true) : setcityError(false)  
        postcode == '' ? setpostcodeError(true) : setpostcodeError(false)  
       
    }


    const setMobile = (event) => {
        if (typeof event === 'undefined') {
            setMobileNumber('');
          } else {
            setMobileNumber(event)
          } 
        if (!isValidMobile(event) && event !== undefined ) { setvalidMobileError(true) }else { setvalidMobileError(false) }
    }


    /*const setLandline = (event) => {
        if (typeof event === 'undefined') {
            setlandlineNumber('');
          } else {
            setlandlineNumber(event)
          }
        if (!isValidLandline(event) && event !== undefined ) { setvalidLandlineNumberError(true) }else { setvalidLandlineNumberError(false) }
    }*/

    const ukAddressSearch = () =>{
        if(isValidPostCode(postcode)){
            setpostcodeError(false)
            axios.post(GET_ADDRESS_LOOKUP_ENDPOINT(),{
                postcode: postcode
            }).then((addressData) => setAddressData(addressData.data.message.data.addresses))
            .catch((err) => console.log(err));
        } else{
            setpostcodeError(true)
        }
    }

    const SelectAddress = () =>{
        return <>
                <h6 className='p-0'>Select Address:</h6>
                <select 
                    name="address" 
                    id="address"
                    style={{color: '#3b3b3b'}}
                    onChange={(e) => {
                        setAddressSelected(e.target.value)
                        setAddress1(e.target.value?.split(",")[0])
                        setAddressLine2(e.target.value?.split(",")[1])
                        setCity(e.target.value?.split(",")[2])
                    }}
                    value={addressSelected}
                >
                    <option key="-" value="--">Click here to select your address</option>
                    {addressData.map((option, index) => <option key={index} value={option.id}>{option.line1}, {option.line2}, {option.city}</option>)}
                </select>
            </>
    }

    const addressSearch = () =>{
        if(ukResident){
            return(<>
            <div className="form-block row g-0">
                <Label label={intl.formatMessage({ id: "Enter your postcode and click search" })} />
                <div className="col-9 pe-0">
                    <Text 
                        onChange={(e) => setPostcode(e.target.value)}
                        id="UK Address Search"
                        value={postcode}
                        placeholder={`${intl.formatMessage({ id:'Enter your postcode here'})}`}
                    />
                </div>
                <div className="col-3 mt-0 px-0">
                    <Button_
                        label={`Search`}
                        style={{width: '100%', padding: '11px 0px', minWidth:'0px', backgroundColor: '#198754'}}
                        className={'mt-0'}
                        onClick={ukAddressSearch}
                    />
                </div>
                {postcodeError || (postcode === undefined && postcode === '')  ?
                    <div>
                        <span className="text-danger">
                            <FormattedMessage id="Please enter your postcode"/>
                        </span>
                    </div>
                    :<></>
                        }
            </div>
            </>)
        }else{
            return (<>
                <div className="form-block">
                    <Label label={intl.formatMessage({ id: "Address Search" })} htmlFor="Address Search" />
                    <ReactGoogleAutocomplete
                        id="Address Search"
                        apiKey={MAP_API_KEY}
                        options={{ types: ['geocode'] }}
                        onPlaceSelected={place => mapAddress(place)}
                        className={'text-input'}
                        placeholder={`${intl.formatMessage({ id: 'Enter your address here'})}`}
                        // onInput={(e) => setAddresss(e.target.value)}
                        onChange={(e) => addFunc(e.target.value)}
                    // onFocus={e => e.target.select()}
                    />

                        {addressError?
                        <div>
                            <span className="text-danger">
                                <FormattedMessage id="Please enter your address"/> 
                            </span>
                        </div>
                        :<></>
                    }
                </div>
    </>)}
    }
    
    return (
        <>
            <div className="heading fdc-form d-flex justify-content-center align-items-center uplers-icon">
                <img src="/uplers-icons/icon2.svg" /> 
                <h2 className="title"><FormattedMessage id="Your address is key to helping the airline locate your details and process your claim" /></h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-block two-block-button">
                            <ListRadioOptions 
                                label={intl.formatMessage({id:'Do you live in the UK?'})}
                                items={[{ text: intl.formatMessage({id:"Yes"}), value: true }, { text: intl.formatMessage({id:"No"}), value: false }]}
                                onSelect={val => {
                                    setUkResident(val)
                                }}
                                value={ukResident}
                                className="d-inline-block"
                                divStyle="full-width"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        {addressSearch()}
                    </div>
                    {addressData && ukResident &&
                    <div className="col-md-12">
                        <div className="form-block">
                             <SelectAddress/> 
                             { addressData?.length === 0 ?
                                <div>
                                    <span className="text-danger">
                                    <FormattedMessage id="Seeing no addresses? Check the entered postcode" />
                                    </span>
                                </div>
                                :ukAddressError || addressSelected === undefined || addressSelected === '' 
                                ? <span className="text-danger">
                                        <FormattedMessage id="Please select an address"/>
                                    </span>
                                :<></>
                            }
                        </div>
                    </div>
                    }
                    {!ukResident && <><div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "Address Line 1" })}
                                onChange={(e) => addressFun(e.target.value)}
                                id="Address Line 1"
                                value={address1}
                                required={false}
                            />

                                {address1Error ||  (address1 === undefined && address1 === '') ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the Address Line 1"/>
                                    </span>
                                </div>
                                :<></>
                              }

                        </div></div>

                    <div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "Address Line 2" })}
                                id="Address Line 2"
                                onChange={(e) => setAddressLine2(e.target.value)}
                                value={addressLine2}
                                required={false}
                            />
                        </div>
                    </div>


                    <div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "City" })}
                                id="City"
                                onChange={(e) => cityFun(e.target.value)}
                                value={city}
                                required={false}
                            />
                                 {cityError || (city === undefined && city === '') ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the city"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "Postcode" })}
                                onChange={(e) => postcodeFun(e.target.value)}
                                id="Postcode"
                                value={postcode}
                                required={false}

                            />
                            
                             {postcodeError || (postcode === undefined && postcode === '')  ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the postcode"/>
                                    </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>

                    {/* <Text
                    label={intl.formatMessage({ id: "County" })}
                    onChange={(e) => setCounty(e.target.value)}
                    value={county}
                /> */}
                    <div className="col-md-12">
                        <div className="form-block">
                            <Text
                                label={intl.formatMessage({ id: "Country" })}
                                id="Country"
                                onChange={(e) => countryFun(e.target.value)}
                                value={country}
                                required={false}
                            />
                             { countryError ?
                                 <div>
                                    <span className="text-danger">
                                        <FormattedMessage id="Please enter the Country Name"/>
                                </span>
                                </div>
                                :<></>
                              }
                        </div>
                    </div>
                </>}
                    <div className="col-md-6 text-center mx-auto">

                        <FormActionButton
                            onNext={() => {
                                if(address === '' || address === undefined){ setaddressError(true) }
                                if(address1 === '' || address1 === undefined){ setaddress1Error(true) }
                                if(city === '' || city === undefined){ setcityError(true) }
                                if(country === '' || country === undefined){ setcountryError(true) }
                                if(postcode === '' || postcode === undefined){ setpostcodeError(true) }
                                if(ukResident && (!addressSelected || addressSelected === '--')){setUkAddressError(true)}
                                

                                if (( address1 && city && postcode && country )
                                      || (ukResident && addressSelected && addressSelected !== '--') ) {
                                    onNext({
                                        ukResident,
                                        address,
                                        addressSelected,
                                        streetNumber,
                                        address1,
                                        addressLine2,
                                        city,
                                        postcode,
                                        country,
                                    }, ( COMPENSATE_SCREEN.OTHER_PASSENGERS ))
                                }
                            }}
                            onBack={() => {
                                onBack(COMPENSATE_SCREEN.ADDRESS - 1)
                            }}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'><FormattedMessage id="ADDRESS encouragement text"/></b>
                    </div>
                </div>
            </div>

        </>
    )
}