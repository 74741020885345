import {
  AIRLINES,
  AIRPORT,
  COMPENSATE_STATE,
  COMPENSATE_STEP,
  SET_LOCALE,
} from "../constants";

export const setAirlines = (data) => {
  return {
    type: AIRLINES,
    data
  }
};

export const setAirports = (data) => {
  return {
    type: AIRPORT,
    data
  }
};

export const setCompensateState = (data) => {
  return {
    type: COMPENSATE_STATE,
    data
  }
};

export const setCompensateStep = (data) => {
  return {
    type: COMPENSATE_STEP,
    data
  }
};

export const setLocale = (data) => {
  return {
    type: SET_LOCALE,
    data,
  };
};