// useLocale.js

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import English from "../lang/en.json";
import German from "../lang/ge.json";
import { setLocale } from "../store/actions/Compensate";

export default function useLocale() {
  const dispatch = useDispatch();
//   const locale = useSelector((state) => state.locale);
   const { locale } = useSelector((state) => state.compensate);
  

  const message = locale === "ge" ? German : English;

  const changeLocale = (lang) => {
    dispatch(setLocale(lang));
  };

  return {
    locale,
    setLocale: changeLocale,
    message,
  };
}
