import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl"
import { faCheck, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { useState, useRef } from "react";
import { useEffect } from "react";
//import MyQRCodeComponent from "../shared/QrCode/MyQRCodeComponent";
export default function End({ compensate, status, CaseURL, isCaseRequest }) {
  const [openModal, setOpenModal] = useState(false)
  const scrollRef = useRef(null);
  //const executeScroll = () => scrollRef.current.scrollIntoView()
  useEffect(() => {
    if (openModal) {
      const modalTimer = setInterval(() => {
        setOpenModal(false)
      }, 5000);
      return () => {
        clearInterval(modalTimer);
      };
    }
  }, [openModal])

  const getPositiveReviewURL = () => {
    return Math.random() < 0.5
      ? 'https://uk.trustpilot.com/evaluate/airtravelclaim.com/?utm_source=trustpilot&utm_medium='
      : 'https://g.page/r/CbGIHshvaS11EAE/review?utm_source=google&utm_medium=';
  };

  return (
    <>
      {openModal && <>
        <div className="firefox-modal">
          <FontAwesomeIcon icon={faCheck} className='pe-1' /><FormattedMessage id="URL copied to clipboard" />
        </div>
      </>}
      <div className="heading uplers-icon fdc-form">
        {/*<img src={`/img/celebratory-image.svg`} alt="icon" style={{height:'275px', display: "block", margin: "auto", marginBottom: "10px"}}/>*/}
        <h2 className="title text-center"><FormattedMessage id="Thank you for submitting your Claim!" /></h2>
      </div>
      {compensate ?
        <div className="fdc-form gif-input pt-0">
          <div className="box-message fw-semibold theme-color-1 text-center">
            <FormattedMessage id="Claim Reference :" /> <b>{compensate?.caseId}</b>
            <br />
            <br />
            <FormattedMessage id="We've sent you a confirmation email, if you haven't received it please check your 'Junk' or 'Spam' folders." />
            <br />
            <br />
            <FormattedMessage id="Rate your experince" />
          </div>
          <div className="row g-0 d-flex justify-content-center">
            <div className='col-md-12 d-flex justify-content-center text-center align-items-baseline review-emojis' ref={scrollRef}>
              <a href="https://www.airtravelclaim.com/customer-review/?utm_source=end_page&utm_medium=fairly_good" target="_blank" rel="noreferrer"><img src='/img/Fairly-Good.png' alt='Fairly Good' /> <span style={{ fontWeight: 'bolder' }}>Fairly Good</span></a>
              <a href={`${getPositiveReviewURL()}good`} target="_blank" rel="noreferrer"><img src='/img/Good.png' alt='Good' /> <span style={{ fontWeight: 'bolder' }}>Good</span></a>
              <a href={`${getPositiveReviewURL()}excellent`} target="_blank" rel="noreferrer"><img src='/img/Excellent.png' alt='Excellent' /> <span style={{ fontWeight: 'bolder' }}>Excellent</span></a>
            </div>
          </div>
        </div>
        : <></>
      }
    </>
  )
}