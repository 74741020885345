import React, { useState, useEffect } from "react";
import { COMPENSATE_SCREEN } from "../../constants";
import { useSelector } from "react-redux";
import GoogleRatingDisplay from "../shared/GoogleRatingDisplay/GoogleRatingDisplay";
import useLocale from "../../hooks/useLocale";

const Header = () => {

    const { compensateStep, compensate } = useSelector(
        (state) => state.compensate
      );
    const { locale, setLocale } = useLocale();
 
    //URL parameters
    const currentUrl = window.location.href;
    const partner = new URL(window.location.href).searchParams.get('p') 
    const Logo = () => {
        if(currentUrl.includes('airdelayed.com')){
            document.documentElement.style.setProperty('--theme-color-1', '#097e76')
            document.documentElement.style.setProperty('--theme-color-2', '#097e76')
            document.documentElement.style.setProperty('--atc-blue', '#312f2f')
            return <img src={`${process.env.PUBLIC_URL}/partner-logos/airdelayed.svg`} alt="" />
        }else if(currentUrl.includes('flightclaims.co.uk')){
            return <img src={`/partner-logos/fc.png`} alt="partner" />
        }else{
            return <img src={`${process.env.PUBLIC_URL}/img/site-logo.png`} alt="" className="mb-2" />
        }
    }

    const [showAtcLogo, setShowAtcLogo] = useState(false)
    const keysArray = Object.keys(COMPENSATE_SCREEN)
    const currentPage = keysArray[compensateStep];

    const handleLanguageChange = (e) => {
      setLocale(e.target.value);
    };

    useEffect(() => {
        if(currentPage === 'SIGNATURE' && currentUrl.includes('flightclaims.co.uk')){
            setShowAtcLogo(true)
        }
        if(currentPage === 'OTHER_PASSENGERS' && !currentUrl.includes('flightclaims.co.uk')){
            setShowAtcLogo(false)
        }
    },[currentUrl, currentPage])

    return (
        <header className="header-wrapper py-3">
            <div className="container">
                <div className="row g-0">
                    <div className="site-logo d-flex align-items-center justify-content-between">
                        <div>
                        <Logo />
                        { !window.location.href.includes('flightclaims.co.uk') && <GoogleRatingDisplay/>} 
                        </div>
                        {/*<div>
                          <select value={locale} onChange={handleLanguageChange}>
                            <option value="en">English</option>
                            <option value="ge">German</option>
                          </select>
                        </div>*/}
                        {partner !== null &&
                            <div className="d-sm-inline-flex align-items-end">
                                <p className="header-p">in association with</p><img src={`/partner-logos/${partner}.png`} alt="partner" className="ps-1" />
                            </div>}
                        {currentUrl.includes('airdelayed.com') && <img src={`${process.env.PUBLIC_URL}/img/site-logo.png`} alt=''/>}
                        {showAtcLogo && <img src={`${process.env.PUBLIC_URL}/img/site-logo.png`} alt=''/>}
                        { /*(window.location.href.includes('airtravelclaim.com') && !window.location.search.includes('p=fc')) && <GoogleRatingDisplay/>*/} 
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;