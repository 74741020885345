import { cloneDeep } from "lodash";
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { COMPENSATE_SCREEN, currencyType, EACH_PASSENGER_CLAIM } from "../../constants";
import Button_ from "../shared/Button";
import FormActionButton from "../shared/FormActionButton";
import ListRadioOptions from "../shared/ListRadioOptions";
import Label from "../shared/Label";
import Text from "../shared/Text";
import Select from "../shared/Select";

export default function OthersPassengers({ compensate, onNext, onBack, submitAction }) {
    const intl = useIntl();
   console.log(compensate)

    const clientData = {
        number: 2,
        firstname: "",
        lastname: "",
        under18: false,
        //bookedandpaid: false,
        email: "",
        mobile: "",
        availableForSignature: false,
        signature: "",
        pchange:false
       

    }
    const [passengers, setPassangers] = useState( compensate.passengers ?? 1)
    const [clients, setClients] = useState(compensate.clients ?? [cloneDeep(clientData)])
    //const [whoBookedAndPaid, setWhoBookedAndPaid] = useState()
    const numbersOfPassengers = Array.from({ length: 20 }, (_, index) => ({
        text: (index + 1).toString(),
        value: index + 1
      }));

    const titleOptions = [
        { text: "Mr", value: "Mr" },
        { text: "Mrs", value: "Mrs" },
        { text: "Ms", value: "Ms" },
        { text: "Miss", value: "Miss" },
        { text: "Mx", value: "Mx" },
    ];

    const updateClient = (index, data) => {
        const clients_ = cloneDeep(clients);
        clients_[index] = { ...clients_[index], ...data }
        setClients(clients_);

      //  const whoBookedAndPaid_ = clients_.findIndex(o => o.bookedandpaid === true);
      //  setWhoBookedAndPaid(whoBookedAndPaid_ > -1 ? whoBookedAndPaid_ : undefined);
    }

    const handlePhoneChange = (i, value) => {

        const updatedData = typeof value === 'undefined' 
            ?{ mobile: "", pchange: true }
            :{ mobile: value, pchange: true };
     
        updateClient(i, updatedData);
      };
      

    const addNewPassenger = () => {
        const clients_ = cloneDeep(clients);
        clients_.push({ ...cloneDeep(clientData), number: clients_.length + 2 })
        setClients(clients_);
    }

    const removeNewPassenger = () => {
        const clients_ = cloneDeep(clients);
        clients_.pop({ ...cloneDeep(clientData), number: clients_.length - 2 })
        setClients(clients_);
    }



    return (
        <>
            <div className="heading fdc-form d-sm-flex uplers-icon">
                <img src={`/img/happy-family.svg`} className="m-auto d-block" style={{width:'15%'}}/> 
              <h2 className="title">
                <FormattedMessage 
                    id="Add all passengers from your booking, each passenger could be owed up to {claimValue} in compensation!"
                    values={{claimValue: <span className="text-danger">{currencyType(compensate)+EACH_PASSENGER_CLAIM(compensate)}</span>}}
                />
                </h2>
            </div>
            <div className="fdc-form gif-input">
                <div className="row">
                <div className="col-md-12">
                        <div className="form-block">
                            <Select
                                label={intl.formatMessage({ id:"Do you have details of the other passengers in your party?"})}
                                className={`d-inline-block`}
                                options={numbersOfPassengers}
                                onChange={setPassangers}
                                value={passengers}
                                placeholder={intl.formatMessage({id:"Click here to select the number of passengers"})}
                                divStyle="full-width"
                            />
                        </div>
                </div>
                    { passengers > 1 && <>
                    {
                        clients.map((client, i) => {
                            return (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">

                                            <div className="form-block">
                                                <Label
                                                    label={intl.formatMessage({ id: "Passenger {number} Details" }, { number: client.number })}
                                                    style={{ fontWeight: 'bold' }}
                                                />
                                            </div>
                                        </div>

                                        {i > 0 ?
                                            <div className="col-md-6 removebutton">
                                                <div className="form-block ">
                                                    <Button_
                                                        label={intl.formatMessage({ id: "- Remove Passenger" })}
                                                        onClick={removeNewPassenger}
                                                    />
                                                </div>
                                            </div> : <></>
                                        }

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <Text
                                                label={intl.formatMessage({ id: "What is their first name?" })}
                                                id={`Passanger ${i+2} Firstname`}
                                                onChange={(e) => updateClient(i, { firstname: e.target.value, fchange: true })}
                                                value={client.firstname}
                                                required={true}
                                            />

                                            <span className={client.firstname === '' && client.fchange ? 'sdisplay' : 'ndisplay'}> <FormattedMessage id="Please enter their first name"/></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-block">
                                            <Text
                                                label={intl.formatMessage({ id: "What is their surname?" })}
                                                id={`Passanger ${i+2} Surname`}
                                                onChange={(e) => updateClient(i, { lastname: e.target.value, lchange: true })}
                                                value={client.lastname}
                                                required={true}
                                            />

                                            <span className={client.lastname === '' && client.lchange ? 'sdisplay' : 'ndisplay'}> <FormattedMessage id="Please enter their last name"/></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-block">
                                            <ListRadioOptions
                                                label={intl.formatMessage({ id: "Is this person under 18?" }) }
                                                items={[{ text: "Yes", value: true }, { text: "No", value: false }]}
                                                onSelect={(val) => {
                                                    const data = {
                                                        under18: val
                                                    }
                                                    updateClient(i, data);
                                                }}
                                                value={client.under18}
                                                className="d-inline-block"
                                                divStyle="full-width"
                                            />
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }
                    <div className="col-md-12">
                        <div className="form-block">
                            <Button_
                                label={intl.formatMessage({ id: "+ Add Another Passenger" })}
                                onClick={addNewPassenger}
                            />
                        </div>
                    </div></>}
                    <div className="col-md-6 text-center mx-auto">

                        <FormActionButton
                            onNext={() => {
                                
                                if(new URL(window.location.href).searchParams.get('utm_medium') === 'Contact Centre') {
                                    submitAction({
                                    clients: passengers < 2 ? [] : clients,
                                    passengers
                                },(COMPENSATE_SCREEN.END))}

                                clients.forEach((el, i) => {
                                    if (!el.fchange) {
                                        clients[i]['fchange'] = true
                                        clients[i]['lchange'] = true
                                        onNext({
                                            clients,
                                            passengers
                                        }, (COMPENSATE_SCREEN.OTHER_PASSENGERS))
                                    } else if (!el.lchange) {
                                        clients[i]['fchange'] = true
                                        clients[i]['lchange'] = true
                                        onNext({
                                            clients:!passengers ? [] : clients,
                                            passengers
                                        }, (COMPENSATE_SCREEN.OTHER_PASSENGERS))
                                    } else {
                                        return false;
                                    }

                                })

                                if (passengers < 2 || (clients.every(o => o.firstname && o.lastname))) {
                                    onNext({
                                        clients: passengers < 2 ? [] : clients,
                                        passengers
                                    }, ( COMPENSATE_SCREEN.OTHER_PASSENGERS + 1))
                                }
                            }}
                            onBack={() => {
                                onBack(COMPENSATE_SCREEN.ADDRESS)
                            }}
                        />
                        <br/>
                        <img src={`/img/light-bulb.svg`} className="light-bulb" alt="Light bulb"/><b className='fst-italic'>{intl.formatMessage({id:"OTHER_PASSENGERS encouragement text"},{claimValue: currencyType(compensate)+EACH_PASSENGER_CLAIM(compensate) * 2})}</b>
                    </div>
                </div>
            </div>
        </>
    )
}